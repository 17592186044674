/* eslint-disable */
import React from "react";
import classNames from "classnames";
import { Route, Switch, Link, withRouter } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

//redux
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions/index";

import { Event } from "react-socket-io";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

const AdminNavbarLinks = props => {
  const classes = useStyles();
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const [depositoNombre, setDepositoNombre] = React.useState('');

  React.useEffect(() => {
    // Recuperar el nombre del depósito desde el localStorage
    const storedDepositoNombre = localStorage.getItem('depositoNombre');
    setDepositoNombre(storedDepositoNombre);
  }, []);

  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = tipoAlerta => {
    setOpenNotification(null);
    if (tipoAlerta == "vencimiento")
      props.history.push("/admin/alertavencimientos");

    if (tipoAlerta == "umbral") props.history.push("/admin/alertaumbral");
  };
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  // console.log(props);
  const f_handleCloseSession = event => {
    event.preventDefault();
    setOpenProfile(null);
    props.handleCloseSession();
  };

  var alertas = [];
  if (props.userAlertas)
    alertas = props.userAlertas.map(elem => {
      return elem.id_alerta;
    });

  var cantidad = 0;
  if (alertas.indexOf(2) > -1 && props.alertasUmbral.cantidad)
    cantidad = cantidad + parseInt(props.alertasUmbral.cantidad);

  if (alertas.indexOf(1) > -1 && props.alertasVencimiento.primer_aviso)
    cantidad = cantidad + parseInt(props.alertasVencimiento.primer_aviso);
  if (alertas.indexOf(1) > -1 && props.alertasVencimiento.segundo_aviso)
    cantidad = cantidad + parseInt(props.alertasVencimiento.segundo_aviso);
  if (alertas.indexOf(1) > -1 && props.alertasVencimiento.vencido)
    cantidad = cantidad + parseInt(props.alertasVencimiento.vencido);

  return (
    <div>
      <div className={classes.searchWrapper}>
        {/*
        <CustomInput
          formControlProps={{
            className: classes.margin + " " + classes.search
          }}
          inputProps={{
            placeholder: "Search",
            inputProps: {
              "aria-label": "Search"
            }
          }}
        />
        <Button color="white" aria-label="edit" justIcon round>
          <Search />
        </Button> */}
      </div>
      {/*
      <Button
        color={window.innerWidth > 959 ? "transparent" : "white"}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-label="Dashboard"
        className={classes.buttonLink}
      >
        <Dashboard className={classes.icons} />
        <Hidden mdUp implementation="css">
          <p className={classes.linkText}>Dashboard</p>
        </Hidden>
      </Button>
    */}
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
        >
          <Notifications className={classes.icons} />
          {cantidad ? (
            <span className={classes.notifications}>{cantidad}</span>
          ) : null}
          <Hidden mdUp implementation="css">
            <p onClick={handleCloseNotification} className={classes.linkText}>
              Notification
            </p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    {alertas.indexOf(1) > -1 &&
                    props.alertasVencimiento &&
                    props.alertasVencimiento.primer_aviso ? (
                      <MenuItem
                        onClick={() => handleCloseNotification("vencimiento")}
                        className={classes.dropdownItem}
                      >
                        Vencimientos - Primer aviso :{" "}
                        {props.alertasVencimiento.primer_aviso}
                      </MenuItem>
                    ) : null}
                    {alertas.indexOf(1) > -1 &&
                    props.alertasVencimiento &&
                    props.alertasVencimiento.segundo_aviso ? (
                      <MenuItem
                        onClick={() => handleCloseNotification("vencimiento")}
                        className={classes.dropdownItem}
                      >
                        Vencimientos - Segundo aviso :{" "}
                        {props.alertasVencimiento.segundo_aviso}
                      </MenuItem>
                    ) : null}
                    {alertas.indexOf(1) > -1 &&
                    props.alertasVencimiento &&
                    props.alertasVencimiento.vencido ? (
                      <MenuItem
                        onClick={() => handleCloseNotification("vencimiento")}
                        className={classes.dropdownItem}
                      >
                        Vencimientos - Vencidos :{" "}
                        {props.alertasVencimiento.vencido}
                      </MenuItem>
                    ) : null}
                    {alertas.indexOf(2) > -1 &&
                    props.alertasUmbral &&
                    props.alertasUmbral.cantidad ? (
                      <MenuItem
                        onClick={() => handleCloseNotification("umbral")}
                        className={classes.dropdownItem}
                      >
                        Umbral de Picking: {props.alertasUmbral.cantidad}
                      </MenuItem>
                    ) : null}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
      <div className={classes.manager}>
        <Hidden smDown>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
              <span>
                Bienvenido, {props.user && <strong>{props.user.nombre}</strong>}
              </span>
              {depositoNombre && (
                <span style={{ color: "#555" }}>Depósito: {depositoNombre}</span>
              )}
            </div>
            <Button
              color={window.innerWidth > 959 ? "transparent" : "white"}
              justIcon={window.innerWidth > 959}
              simple={!(window.innerWidth > 959)}
              aria-owns={openProfile ? "profile-menu-list-grow" : null}
              aria-haspopup="true"
              onClick={handleClickProfile}
              className={classes.buttonLink}
            >
              <Person className={classes.icons} />
              <Hidden mdUp implementation="css">
                <p className={classes.linkText}>Perfil</p>
              </Hidden>
            </Button>
          </div>
        </Hidden>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    {/*
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Profile
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Settings
                    </MenuItem>
                    <Divider light />
                  */}
                    <MenuItem
                      onClick={event => f_handleCloseSession(event)}
                      className={classes.dropdownItem}
                    >
                      Cerrar Sesión
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
      {alertas.indexOf(1) > -1 && (
        <Event
          event="alertasVencimiento"
          handler={data => {
            props.setAlertasVencimiento(data);
          }}
        />
      )}
      {alertas.indexOf(2) > -1 && (
        <Event
          event="alertasUmbral"
          handler={data => props.setAlertasUmbral(data)}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    alertasVencimiento: state.alertas.alertasVencimiento,
    alertasUmbral: state.alertas.alertasUmbral
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlertasUmbral: data => dispatch(actionCreators.setAlertasUmbral(data)),
    setAlertasVencimiento: data => {
      dispatch(actionCreators.setAlertasVencimiento(data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminNavbarLinks));
