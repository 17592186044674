import React, { Component } from "react";
import InputNew from "components/Input/InputNew";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/styles";

import Database from "variables/Database.js";
import {
  inputChangedHandler,
  inputAllChangedHandler
} from "variables/input.js";
import { toast } from "react-toastify";

import ModalMapa from "../../Mapa/ModalMapa.js";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import Save from "@material-ui/icons/Save";

import { StateAlmacen } from "../VariablesState";
import ArrowBack from "@material-ui/icons/ArrowBack";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class EditAlmacen extends Component {
  state = JSON.parse(JSON.stringify(StateAlmacen));

  getAlmacenEdit = id => {
    Database.get("/list-almacenes/" + id, this).then(
      resultado => {
        let objeto = resultado.result[0] || null;
        if (objeto) {
          let orderFormCopy = { ...this.state.orderForm };

          orderFormCopy.nombre.value = objeto.nombre || null;
          orderFormCopy.direccion.value = objeto.direccion || null;
          orderFormCopy.telefono.value = objeto.telefono || null;
          orderFormCopy.largo.value = objeto.largo || null;
          orderFormCopy.ancho.value = objeto.ancho || null;

          let { orderForm, formIsValid } = inputAllChangedHandler(
            orderFormCopy
          );

          this.setState({
            orderForm: orderForm,
            formIsValid: formIsValid
          });
        }
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handleSubmitForm = event => {
    event.preventDefault();

    let { orderForm, formIsValid } = inputAllChangedHandler(
      this.state.orderForm
    );
    this.setState({ orderForm: orderForm, formIsValid: formIsValid });

    if (formIsValid) {
      let objeto = {};
      for (let key in this.state.orderForm) {
        objeto[key] = this.state.orderForm[key].value;
      }
      this.setState({
        disableAllButtons: true
      });
      Database.post(`/update-almacenes`, {
        //id: process.env.REACT_APP_ID_ALMACEN,
        id: this.props.match.params.idalmacen,
        ...objeto
      }).then(
        res => {
          this.setState(
            {
              disableAllButtons: false
            },
            () => {
              toast.success("El Almacen se ha modificado con exito!");
              this.props.getAlmacenesAdmin();
            }
          );
        },
        err => {
          toast.error(err.message);
        }
      );
    } else {
      toast.error("verificar los campos en el formulario");
    }
  };

  handleModalClose = () => {
    this.setState({
      openMapaDialog: false
    });
  };

  componentDidMount() {
    //let idAlmacen = process.env.REACT_APP_ID_ALMACEN;
   // this.getAlmacenEdit(idAlmacen);
    this.getAlmacenEdit(this.props.match.params.idalmacen);
  }

  render() {
    const formElementsArray = [];
    for (let key in this.state.orderForm) {
      formElementsArray.push({
        id: key,
        config: this.state.orderForm[key]
      });
    }

    return [
      <form
        key="formu-almacen"
        onSubmit={event => {
          this.handleSubmitForm(event);
        }}
      >
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>
              Editar Almacen
            </h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Formulario para modificar los datos del almacen
            </p>
          </CardHeader>
          <CardBody>
            {/* <Button style={{ marginTop: '25px' }} color="secondary" variant="contained"  type="button" onClick={ () => this.setState({ openMapaDialog:true } ) }  >abrir mapa</Button> */}

            <div className="mt-3 mb-3">
              {formElementsArray.map(formElement => (
                <InputNew
                  key={"input-" + formElement.id}
                  objeto={formElement.config}
                  changed={event => {
                    let { orderForm, formIsValid } = inputChangedHandler(
                      event,
                      formElement.id,
                      this.state.orderForm
                    );
                    this.setState({
                      orderForm: orderForm,
                      formIsValid: formIsValid
                    });
                  }}
                />
              ))}
            </div>

            {/* <div>
              <InputNew
                objeto={this.state.orderForm.nombre}
                changed={(event) => {
                  let { orderForm, formIsValid } = inputChangedHandler(event, 'nombre', this.state.orderForm);
                  this.setState({ orderForm: orderForm, formIsValid: formIsValid })
                }}
              />


            </div> */}

            <Button style={{ marginTop: '25px' }} color="info" onClick={() => this.props.history.push('/admin/almacenes')} ><ArrowBack />Volver</Button>
            <Button
              style={{ marginTop: "25px" }}
              color="primary"
              variant="contained"
              disabled={this.state.disableAllButtons}
              type="submit"
            >
              <Save /> Guardar
            </Button>
          </CardBody>
        </Card>
        <ModalMapa
          openDialog={this.state.openMapaDialog}
          handleClose={() => this.handleModalClose()}
        />
      </form>
    ];
  }
}

export default withRouter(withStyles(styles)(EditAlmacen));
