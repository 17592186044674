/* eslint-disable */
//MODULOS GENERALES
import React, { Component } from "react";
import Database from "variables/Database.js";
import moment from "moment";
import { toast } from "react-toastify";

//ESTILOS Y COLORES
import { withStyles } from "@material-ui/styles";
import yellow from "@material-ui/core/colors/yellow";
import green from "@material-ui/core/colors/green";
import blueGrey from "@material-ui/core/colors/blueGrey";

//CONTENEDORES
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const styles = {};

class DetalleSalidas extends Component {
  state = {
    detalle: []
  };

  componentDidMount() {
   // console.log("Capturando id_pedido : " + this.props.id_pedido)
    Database.get(
      "/list-pedidos-auditoria-detalle/" +
        this.props.id_pedido,
      this
    ).then(
      res => {
        console.log("Auditoria Pedido Detalle .js");
        console.log(res);
        let resultado = [...res.result[0]];

        for (let i = 0; i < resultado.length; i++) {
          if (resultado[i].dun14 === null) {
            resultado[i].dun14 = "-";
          }
          if (resultado[i].ean3 === null) {
            resultado[i].ean3 = "-";
          }
        }

        this.setState({
          detalle: resultado
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  }

  render() {
    return (
      <Table style={{ backgroundColor: blueGrey[50] }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Dato Logístico</TableCell>
            <TableCell>DUN 14</TableCell>
            <TableCell>EAN 13</TableCell>
            <TableCell>Bultos Cumplidos</TableCell>
            <TableCell>Displays Cumplidos</TableCell>
            <TableCell>Unidades Cumplidas</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.detalle.map(elem => {
            return (
              <TableRow key={elem.id}>
                <TableCell>{elem.producto}</TableCell>
                <TableCell>{elem.dun14}</TableCell>
                <TableCell>{elem.ean13}</TableCell>
                <TableCell>{elem.cantidad_bultos_cumplidos}</TableCell>
                <TableCell>{elem.cantidad_displays_cumplidos}</TableCell>
                <TableCell>{elem.cantidad_unidades_cumplidas}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

export default withStyles(styles)(DetalleSalidas);
