import React, { Component } from 'react'
import Database from 'variables/Database.js'
import { Switch } from 'react-router-dom'

// import { AddBox, ArrowUpward } from "@material-ui/icons";
// import ReactDOM from "react-dom";
import MaterialTable from 'material-table'
import { toast } from 'react-toastify'
import moment from 'moment'
import { localization } from 'variables/general.js'
import lightGreen from '@material-ui/core/colors/lightGreen'
// import ChecklistIcon from '@mui/icons-material/Checklist'

import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Card from 'components/Card/Card.js'

import DetallePedidos from './components/DetallePedidos'
//import ModalDelete from './components/ModalDelete'
//import SalidasCheckList from './components/SalidasCheckList.js'

import { withStyles } from '@material-ui/styles'

const columns = [
    { title: 'N° Pedido', field: 'id', editable: 'never' },
   // { title: 'Referencia', field: 'referencia', editable: 'never' },
    { title: 'Destino', field: 'destino', editable: 'never' },
    { title: 'Cliente', field: 'razon_social', editable: 'never' },
   // { title: 'Calle Salida', field: 'calle_salida', editable: 'never' },
    {
        title: 'Fecha Pedido',
        field: 'fecha_pedido',
        editable: 'never',
        customSort: (a, b) =>
            parseInt(moment(a.fecha_pedido).format('YYYYMMDDHHmmSS')) -
            parseInt(moment(b.fecha_pedido).format('YYYYMMDDHHmmSS')),
    },
    {
        title: 'Fecha auditada',
        field: 'fecha_proceso',
        editable: 'never',
        customSort: (a, b) =>
            parseInt(moment(a.fecha_proceso).format('YYYYMMDDHHmmSS')) -
            parseInt(moment(b.fecha_proceso).format('YYYYMMDDHHmmSS')),
    },
    { title: 'Recolector', field: 'trabajador', editable: 'never' },
    // { title: "Orden de Picking Asignada", field: "id_orden_picking", editable: 'never' },
    // {
    //     title: 'Patente del Camión',
    //     field: 'patente',
    //     editable: 'onUpdate',
    //     cellStyle: { backgroundColor: 'rgba(104, 159, 56, 0.2)' },
    // },
    { title: 'Estado', field: 'estado', editable: 'never' },
]

/*
render: rowData => <img src={rowData.url} style={{width: 50, borderRadius: '50%'}}
 <Moment format="YYYY/MM/DD">
                1976-04-19T12:59-0500
            </Moment>
 */

const styles = {
    cardCategoryWhite: {
        '&,& a,& a:hover,& a:focus': {
            color: 'rgba(255,255,255,.62)',
            margin: '0',
            fontSize: '14px',
            marginTop: '0',
            marginBottom: '0',
        },
        '& a,& a:hover,& a:focus': {
            color: '#FFFFFF',
        },
    },
    cardTitleWhite: {
        color: '#FFFFFF',
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: '300',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: '3px',
        textDecoration: 'none',
        '& small': {
            color: '#777',
            fontSize: '65%',
            fontWeight: '400',
            lineHeight: '1',
        },
    },
}

class Entradas extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // Lo siguiente son los campos para el checkeo del pedido
            openCheckList: false, // Inicialmente, openCheckList es falso
        }
    }

    state = {
        listado: [],
        listadoCheckList: [],
        actions: [],
        isLoading: false,
    }

    getItems = () => {
        this.setState({
            isLoading: true,
        })
        Database.get('/list-pedidos-auditoria', this, null, true).then(
            (res) => {
                this.setState({
                    isLoading: false,
                })
                let resultado = [...res.result[0]]
                resultado = resultado.map((elem) => {
                    return {
                        ...elem,
                        //  devolucion: ((elem.es_devolucion == 0) ? 'NO' : 'SI'),
                        fecha_pedido: moment(elem.fecha_pedido).format('DD/MM/YYYY HH:mm'),
                        fecha_proceso: moment(elem.fecha_proceso).format('DD/MM/YYYY HH:mm'),
                    }
                })

                this.setState({
                    listado: resultado,
                })
            },
            (err) => {
                toast.error(err.message)
            }
        )
    }

    componentDidMount() {
        this.getItems()
    }

    handleDeleteButton = (rowData) => {
        this.setState({
            openDeleteDialog: true,
            deleteRowData: rowData,
        })
    }

    handleModalClose() {
        this.setState({
            openDeleteDialog: false,
            deleteRowData: null,
        })
    }

    handleComprobarPedido = (rowData) => {
        // console.log('valores de la fila', rowData)
        this.setState({
            listadoCheckList: rowData,
            openCheckList: true,
        })
    }

    handleCerrarCheckList = () => {
        this.setState({
            openCheckList: false,
            // Otras actualizaciones de estado si es necesario
        })
    }

    //cambia el estado del pedido a cancelado y envia las opp.cantidad_unidades_cumplidas displays bultos A u.id_ubicaciones_picking
    handleDelete = (rowData) => {
        Database.post(
            '/cancel-pedidos-salidas',
            {
                id: rowData.id,
          //: rowData.id_calle_salida,
                id_almacen: process.env.REACT_APP_ID_ALMACEN,
            },
            this
        ).then(
            (res) => {
                this.getItems()

                this.setState({
                    openDeleteDialog: false,
                })

                toast.success(
                    'El pedido de la calle seleccionada ha sido cancelado y enviado a picking con exito!'
                )
            },
            (err) => {
                toast.error(err.message)
            }
        )
    }

    //todo en tabla pedidos, cambia el estado del pedido a despachado y almacena la patente cargada
    handleDespacharPedido = (rowData) => {
        Database.post(
            '/despachar-pedidos-salidas',
            {
                id: rowData.id,
            //    patente: rowData.patente,
             //   id_calle_salida: rowData.id_calle_salida,
            },
            this
        ).then(
            (res) => {
                //actualiza la info
                this.getItems()

                toast.success(
                    'El pedido de la calle seleccionada ha sido despachado con exito!'
                )
            },
            (err) => {
                toast.error(err.message)
            }
        )
    }

    render() {
        let style = {}
        if (this.props.match.url != this.props.location.pathname) {
            style = { display: 'none' }
        }
        return (
            <div style={{ maxWidth: '100%' }}>
                {!this.state.openCheckList && (
                    <Card style={style}>
                        <CardHeader color='primary'>
                            <h4 className={this.props.classes.cardTitleWhite}>AUDITORIA DE PEDIDOS</h4>
                            <p className={this.props.classes.cardCategoryWhite}>
                                Listado de Auditoría de Pedidos
                            </p>
                        </CardHeader>
                        <CardBody>
                            <MaterialTable
                                isLoading={this.state.isLoading}
                                columns={columns}
                                data={this.state.listado}
                                title=''
                                actions={this.state.actions}
                                localization={localization}
                                options={{
                                    filtering: true,
                                    actionsColumnIndex: -1,
                                    exportButton: true,
                                    exportAllData: true,
                                    exportFileName: 'Salidas ' + moment().format('DD-MM-YYYY'),
                                    exportDelimiter: ';',
                                    headerStyle: {
                                        backgroundColor: lightGreen[700],
                                        color: '#FFF',
                                    },
                                }}
                                detailPanel={(rowData) => {
                                     return (
                                         <DetallePedidos
                                              id_pedido={rowData.id}
                                           //   id_calle_salida={rowData.id_calle_salida}
                                          />
                                     )
                                }}
                                // editable={{
                                //     onRowUpdate: (newData, oldData) =>
                                //         new Promise((resolve, reject) => {
                                //             setTimeout(() => {
                                //                 var dataUpdate = [...this.state.listado]
                                //                 const index = oldData.tableData.id
                                //                 dataUpdate[index] = newData
                                //                 // setData([...dataUpdate]);
                                //                 this.setState({ listado: dataUpdate })
                                //                 resolve()
                                //             }, 1000)
                                //         }),
                                // }}
                                // actions={[
                                //     (rowData) =>
                                //         rowData.estado !== 'despachado' &&
                                //             rowData.estado !== 'cancelado'
                                //             ? {
                                //                 //si no está despachado y no cancelado
                                //                 icon: 'checklist',
                                //                 tooltip: 'Comprobar el pedido',
                                //                 onClick: (event, rowData) =>
                                //                     this.handleComprobarPedido(rowData),
                                //             }
                                //             : null,
                                //     (rowData) =>
                                //         rowData.estado !== 'despachado' &&
                                //             rowData.estado !== 'cancelado'
                                //             ? {
                                //                 //si no está despachado y no cancelado
                                //                 icon: 'local_shipping',
                                //                 tooltip: 'Enviar al Camión',
                                //                 onClick: (event, rowData) =>
                                //                     this.handleDespacharPedido(rowData),
                                //             }
                                //             : null,
                                //     (rowData) =>
                                //         rowData.estado !== 'despachado' &&
                                //             rowData.estado !== 'cancelado'
                                //             ? {
                                //                 //si no está despachado y no cancelado
                                //                 icon: 'cancel',
                                //                 tooltip: 'Cancelar y Enviar a Picking',
                                //                 onClick: (event, rowData) =>
                                //                     this.handleDeleteButton(rowData),
                                //             }
                                //             : null,
                                // ]}
                            />
                        </CardBody>
                    </Card>
                )}
                {/* {this.state.openCheckList && (
                    // <SalidasCheckList
                    //     handleCloseCheckList={this.handleCerrarCheckList}
                    //     dataListado={this.state.listadoCheckList}
                    // />
                )} */}

                <Switch>
                    {/* <Route
            path={this.props.match.url + '/detalle/:idSalida'}
            exact
            render={() => <DetallePrint />}
          /> */}

                    {/* <ModalDelete
                        openDeleteDialog={this.state.openDeleteDialog}
                        deleteRowData={this.state.deleteRowData}
                        handleClose={() => this.handleModalClose()}
                        handleDelete={(rowData) => this.handleDelete(rowData)}
                    /> */}
                </Switch>
            </div>
        )
    }
}

export default withStyles(styles)(Entradas)
