import React, { Component } from "react";
import Database from "variables/Database.js";
import moment from "moment";

import { Route, Switch, Link } from "react-router-dom";
// core components
import MaterialTable, { MTableCell, MTableBodyRow } from "material-table";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Paper from "@material-ui/core/Paper";
import Button from "components/CustomButtons/Button.js";
import AddIcon from "@material-ui/icons/Add";

import EditAlmacen from "./components/EditAlmacen.js";
import ModalDelete from "./components/ModalDelete.js";
import { localization } from "variables/general.js";

import { toast } from "react-toastify";

import { StateListAlmacenes, ColumnsListado } from "./VariablesState";

import lightGreen from "@material-ui/core/colors/lightGreen";
import NewAlmacen from "./components/NewAlmacen.js";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class Almacenes extends Component {
  state = { ...StateListAlmacenes };

  componentDidMount() {
    this.getAlmacenesAdmin();
  }

  handleToggle = value => {
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];
    let deleteEnabled = false;
    let editEnabled = false;
    const botonesAcc = { ...this.state.botonesAcciones };
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (newChecked.length > 0) {
      deleteEnabled = true;
      if (newChecked.length == 1) editEnabled = true;
    }
    botonesAcc.editar.enabled = editEnabled;
    botonesAcc.delete.enabled = deleteEnabled;
    this.setState({
      botonesAcciones: botonesAcc,
      checked: newChecked
    });
  };

  menuHandleClose = value => {
    this.setState({
      menuContext: null
    });
  };

  menuHandleItemClick = value => {
    const newItem = { ...this.state.botonesAcciones[value] };
    let menuContext = { ...this.state.menuContext };
    if (newItem.enabled) {
      menuContext = null;

      if (value == "nuevo") {
        this.setState({
          menuContext: menuContext
        });
        this.props.history.push(this.props.match.url + "/nuevoalmacen");
      }

      if (value == "editar" && this.state.checked.length == 1) {
        this.setState({
          menuContext: menuContext
        });
        let idAlmacen = this.state.checked[0].id;
        this.props.history.push(
          this.props.match.url + "/editaralmacen/" + idAlmacen
        );
      }
    }
  };

  menuHandleOpen = event => {
    this.setState({
      menuContext: event.currentTarget
    });
  };
  ////////////////////////
  ////////////////////////
  //METODOS PARA LISTADO DE ALMACENES
  ////////////////////////
  ////////////////////////
  getAlmacenesAdmin = () => {
    this.setState({
      isLoading: true
    });

    Database.get("/list-almacenes", this, null, true).then(
      res => {
        let resultado = [...res.result];
        // console.log(resultado);
        this.setState({
          isLoading: false,
          almacenes: resultado,
          checked: [],
          menuContext: null,
          botonesAcciones: {
            nuevo: {
              enabled: true,
              texto: "Nuevo"
            },
            editar: {
              enabled: false,
              texto: "Editar"
            }
          }
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  editSingleAlmacen = value => {
    this.props.history.push(this.props.match.url + "/editaralmacen/" + value);
  };

  handlePagination = offset => {
    this.setState({
      offset: offset
    });
  };

  handleDeleteAlmacen = rowData => {
    Database.post("/delete-almacen", { id: rowData.id }, this).then(
      res => {
        let almacenes = [...this.state.almacenes];
        almacenes = almacenes.filter(elem => {
          if (elem.id == rowData.id) return false;

          return true;
        });

        this.setState(
          {
            almacenes: almacenes,
            openDeleteDialog: false
          },
          () => {
            toast.success("El almacen se ha eliminado con exito!");
          }
        );
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handleDeleteButton = rowData => {
    this.setState({
      openDeleteDialog: true,
      deleteRowData: rowData
    });
  };

  handleModalClose() {
    this.setState({
      openDeleteDialog: false,
      deleteRowData: null
    });
  }

  render() {
    let style = {};
    if (this.props.match.url != this.props.location.pathname) {
      style = { display: "none" };
    }
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={style}>
            <CardHeader color="primary">
              <h4 className={this.props.classes.cardTitleWhite}>Almacenes</h4>
              <p className={this.props.classes.cardCategoryWhite}>
                Listado de Almacenes
              </p>
            </CardHeader>
            <CardBody>
              <Button
                style={{ marginTop: "25px" }}
                onClick={() =>
                  this.props.history.push(
                    this.props.match.url + "/nuevoalmacen"
                  )
                }
                color="primary"
              >
                <AddIcon /> Nuevo Almacen
              </Button>
              <MaterialTable
                isLoading={this.state.isLoading}
                columns={ColumnsListado}
                data={this.state.almacenes}
                title=""
                localization={localization}
                actions={[
                  {
                    icon: "edit",
                    tooltip: "Editar Almacen",
                    onClick: (event, rowData) =>
                      this.props.history.push(
                        this.props.match.url + "/editaralmacen/" + rowData.id
                      )
                  },
                  // {
                  //   icon: "delete",
                  //   tooltip: "Borrar Almacen",
                  //   onClick: (event, rowData) =>
                  //     this.handleDeleteButton(rowData)
                  // }
                ]}
                components={{
                  Container: props => <Paper elevation={0} {...props} />
                }}
                options={{
                  exportButton: true,
                  exportAllData: true,
                  exportFileName: "Almacenes " + moment().format("DD-MM-YYYY"),
                  exportDelimiter: ";",
                  headerStyle: {
                    backgroundColor: lightGreen[700],
                    color: "#FFF"
                  }
                }}
              />
            </CardBody>
          </Card>

          <Switch>
            <Route
              path={this.props.match.url + "/nuevoalmacen"}
              render={() => (
                <NewAlmacen
                  getAlmacenesAdmin={() => this.getAlmacenesAdmin()}
                  handleListNewUser={rowData => this.handleListNewUser(rowData)}
                />
              )}
            />
            <Route
              path={this.props.match.url + "/editaralmacen/:idalmacen"}
              render={() => (
                <EditAlmacen
                  orderForm={this.state.editAlmacenForm}
                  editFormIsValid={this.state.editFormIsValid}
                  successSubmitEdit={this.state.successSubmitEdit}
                  handleSubmitEditAlmacen={event => {
                    this.handleSubmitEditAlmacen(event);
                  }}
                  inputEditChangedHandler={(event, inputIdentifier) =>
                    this.inputEditChangedHandler(event, inputIdentifier)
                  }
                  getAlmacenEdit={id => {
                    this.getAlmacenEdit(id);
                  }}
                  resetEditForm={this.resetEditForm}
                  reloadAlmacenes={this.reloadAlmacenes}
                  getAlmacenesAdmin={() => this.getAlmacenesAdmin()}
                />
              )}
            />
          </Switch>
        </GridItem>
        <ModalDelete
          openDeleteDialog={this.state.openDeleteDialog}
          deleteRowData={this.state.deleteRowData}
          handleClose={() => this.handleModalClose()}
          handleDelete={rowData => this.handleDeleteAlmacen(rowData)}
        />
      </GridContainer>
    );
  }
}

export default withStyles(styles)(Almacenes);
