import React, { Component } from "react";
import Input from "components/Input/Input";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/styles";

import Database from "variables/Database.js";
import { toast } from "react-toastify";

import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Card from "components/Card/Card.js";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/Save";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { StateEditUser } from "../VariablesState";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

global.id_usuarios_clientes = 29;

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class EditUser extends Component {
 // state = JSON.parse(JSON.stringify(StateEditUser));
  state = {
    ...StateEditUser,
    almacenes: [], // Añadimos almacenes al estado principal
    isCheckboxChecked: false,
  }
  handleClickOpen = () => {
    this.setState({
      openChangePass: true
    });
  };

  handleClose = () => {
    this.setState({
      openChangePass: false
    });
  };

  getUsersType = () => {
    Database.get("/list-users_type", this).then(
      res => {
        let resultadoUserType = [...res.result];
        let a = [];
        resultadoUserType.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.descripcion
          });
        });

        let formulario = { ...this.state.editUserForm };
        formulario.tipoUser.elementConfig.options = [...a];
        this.setState({
          editUserForm: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  getListClientes = () => {
    Database.get("/list-clientes", this).then(
      res => {
        let resultadoListClientes = [...res.result];
        let a = [];
        resultadoListClientes.forEach(function(entry) {
          a.push({
            value: entry.id,
            displayValue: entry.razon_social
          });
        });
        let formulario = { ...this.state.editUserForm };
        formulario.cliente.elementConfig.options = [...a];
        this.setState({
          editUserForm: formulario
        });
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  checkValidity = (value, rules) => {
    let isValid = true;
    let textValid = null;

    if (rules.required && isValid) {
      isValid = value.toString().trim() !== "";
      textValid = "El campo es requerido";
    }

    if (rules.minLength && isValid) {
      isValid = value.length >= rules.minLength;
      textValid = "La cantidad de caracteres minimos es " + rules.minLength;
    }

    if (rules.maxLength && isValid) {
      isValid = value.length <= rules.maxLength;
      textValid = "Supera el maximo de caracteres";
    }

    return { isValid: isValid, textValid: textValid };
  };

  getUserEdit = id => {
    Database.get("/list-users/" + id).then(resultado => {
      if (resultado.usuario.length > 0) {
        this.setState({
          userEdit: resultado.usuario[0]
        });

        let editUserFormAlt = { ...this.state.editUserForm };
        editUserFormAlt.username.value = resultado.usuario[0].username;
        editUserFormAlt.nombre.value = resultado.usuario[0].nombre;
        editUserFormAlt.tipoUser.value = resultado.usuario[0].id_users_type;
        editUserFormAlt.cliente.value = resultado.usuario[0].id_cliente;
        for (let key in editUserFormAlt) {
          editUserFormAlt[key].touched = true;
          editUserFormAlt[key].valid = true;
        }
        this.getUsersType("edit", editUserFormAlt);
        this.getListClientes("edit", editUserFormAlt);
        
        // Actualiza almacenes con el estado de checked basado en la lógica que tienes
        /*const almacenesActualizados = resultado.almacenes.map((elem) => {
          let indexResultado = resultado.detalleAlmacenes.findIndex(
            (elem2) => elem2.id_almacen === elem.id
          );
          elem.checked = indexResultado > -1;
          return elem;
        });*/
        const almacenesActualizados = resultado.almacenes.map((elem) => {
          let indexResultado = resultado.detalleAlmacenes.findIndex(elem2 => {
            return elem2.id_almacen == elem.id;
          });
          if (indexResultado > -1) {
            elem.checked = true;
          } else {
            elem.checked = false;
          }
         // elem.id_users_type = resultado.tipoUsuario[0].id;

          return elem;
        });


        // Elimina detalleAlmacenes del objeto resultado
        delete resultado.detalleAlmacenes;

        // Actualiza el estado con los datos del usuario y los almacenes
        this.setState({
          userEdit: resultado.usuario[0],
          almacenes: almacenesActualizados,
        });


        console.log(this.state.editUserForm);
      } else {
        this.setState({
          userEdit: null
        });
      }
    });
  };

  handleChangePass = event => {
    event.preventDefault();
    this.setState({
      openChangePass: false
    });

    Database.post(`/update-pass`, {
      id: this.props.match.params.iduser,
      newpass: event.target.contrasenia.value
    }).then(
      res => {
        toast.success("El Usuario se ha modificado con exito!");
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  handleSubmitEditUser = event => {
    event.preventDefault();

    if (
      this.state.editUserForm.tipoUser.value === global.id_usuarios_clientes &&
      this.state.editUserForm.cliente.value === ""
    ) {
      return toast.error("¡Seleccione un cliente!");
    }

    Database.post(`/update-user`, {
      id: this.props.match.params.iduser,
      username: this.state.editUserForm.username.value,
      nombre: this.state.editUserForm.nombre.value,
      id_users_type: this.state.editUserForm.tipoUser.value,
      id_cliente: this.state.editUserForm.cliente.value,
      almacenes: this.state.almacenes,
    }).then(
      res => {
        this.setState(
          {
            successSubmitEdit: true,
            editFormIsValid: false,
            disableAllButtons: false
          },
          () => {
            toast.success("El Usuario se ha modificado con exito!");

            this.props.getUsersAdmin();
          }
        );
      },
      err => {
        toast.error(err.message);
      }
    );
  };

  inputEditChangedHandler = (event, inputIdentifier) => {
    let checkValid;
    const updatedOrderForm = {
      ...this.state.editUserForm
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier]
    };
    if (
      inputIdentifier === "tipoUser" &&
      event.target.value !== global.id_usuarios_clientes
    ) {
      updatedOrderForm["cliente"].value = "";
    }

    if (
      inputIdentifier === "cliente" &&
      updatedOrderForm["tipoUser"].value !== global.id_usuarios_clientes
    ) {
      return toast.error(
        "¡Para seleccionar cliente el tipo de usuario debe ser cliente!"
      );
    }

    updatedFormElement.value = event.target.value;
    checkValid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.valid = checkValid.isValid;
    updatedFormElement.textValid = checkValid.textValid;
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValidAlt = true;
    for (let inputIdentifier in updatedOrderForm) {
      formIsValidAlt =
        updatedOrderForm[inputIdentifier].valid && formIsValidAlt;
    }
    console.log(updatedOrderForm);
    this.setState({
      editUserForm: updatedOrderForm,
      editFormIsValid: formIsValidAlt
    });
  };

  handleCheckbox = (event, index) => {
    const almacenesActualizados = [...this.state.almacenes];
    almacenesActualizados[index].checked = event.target.checked;
    const isAnyChecked = almacenesActualizados.some(almacen => almacen.checked);
    // Actualiza el estado con los nuevos valores de almacenes
    this.setState({
      almacenes: almacenesActualizados,
      isCheckboxChecked: isAnyChecked,
    });
  };

  resetEditForm = () => {
    let editUserFormAlt = { ...this.state.editUserForm };
    let successSubmitEdit = this.state.successSubmitEdit;
    for (let key in editUserFormAlt) {
      editUserFormAlt[key].value = "";
    }

    this.setState({
      editFormIsValid: false,
      successSubmitEdit: successSubmitEdit
    });
  };

  componentDidMount() {
    this.getUsersType();
    this.getListClientes();
    this.getUserEdit(this.props.match.params.iduser);
  }

  render() {
    const formElementsArray = [];
    for (let key in this.state.editUserForm) {
      formElementsArray.push({
        id: key,
        config: this.state.editUserForm[key]
      });
    }

    return [
      <form
        key="form-edit-user"
        onSubmit={event => {
          this.handleSubmitEditUser(event);
        }}
      >
        <Card>
          <CardHeader color="primary">
            <h4 className={this.props.classes.cardTitleWhite}>
              Editar Usuario
            </h4>
            <p className={this.props.classes.cardCategoryWhite}>
              Formulario para modificar los datos del usuario
            </p>
          </CardHeader>
          <CardBody>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.handleClickOpen}
            >
              Cambiar Contraseña
            </Button>

            <div className="mt-3 mb-3">
              {formElementsArray.map(formElement =>
                formElement.config.elementConfig.label !== "Cliente" ? (
                  <Input
                    key={"edituser-" + formElement.id}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    value={formElement.config.value}
                    textValid={formElement.config.textValid}
                    invalid={!formElement.config.valid}
                    shouldValidate={formElement.config.validation}
                    touched={formElement.config.touched}
                    changed={event =>
                      this.inputEditChangedHandler(event, formElement.id)
                    }
                  />
                ) : //  formElement.config.elementConfig.label === 'Cliente' && this.state.EditUserForm?.tipoUser?.value === global.id_usuarios_clientes ?
                formElement.config.elementConfig.label === "Cliente" ? (
                  <Input
                    key={"edituser-" + formElement.id}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    value={formElement.config.value}
                    textValid={formElement.config.textValid}
                    invalid={!formElement.config.valid}
                    shouldValidate={formElement.config.validation}
                    touched={formElement.config.touched}
                    changed={event =>
                      this.inputEditChangedHandler(event, formElement.id)
                    }
                  />
                ) : null
              )}
            </div>
            <div className="mt-3 mb-3">
              <h4>Depósitos: </h4>
              <FormGroup row>
                {this.state.almacenes.map((elem, index) => (
                  <FormControlLabel
                    key={"label-" + index}
                    control={
                      <Checkbox
                        checked={elem.checked}
                        onChange={(event) => this.handleCheckbox(event, index)}
                        value={"id_almacen" + elem.id}
                      />
                    }
                    label={elem.nombre}
                  />
                ))}
              </FormGroup>
            </div>

            <Button
              style={{ marginTop: "25px" }}
              color="default"
              onClick={() => this.props.history.push("/admin/usuarios")}
            >
              <ArrowBack />
              Volver
            </Button>
            <Button
              style={{ marginTop: "25px" }}
              color="primary"
              variant="contained"
              // disabled={
              //   !this.state.editFormIsValid || this.state.disableAllButtons ||
              //   !this.state.isCheckboxChecked // Asegúrate de que el checkbox esté marcado
              // }
              type="submit"
            >
              <Save /> Guardar
            </Button>
          </CardBody>
        </Card>
      </form>,

      <Dialog
        key="modal-contrasena"
        open={this.state.openChangePass}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Cambio de Contraseña</DialogTitle>
        <form
          onSubmit={event => {
            this.handleChangePass(event);
          }}
        >
          {this.state.openChangePass && (
            <DialogContent>
              <DialogContentText>
                Ingrese una nueva contraseña para el Usuario
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="contrasenia"
                name="contrasenia"
                label="nueva contraseña"
                type="password"
                fullWidth
              />
            </DialogContent>
          )}
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancelar
            </Button>
            <Button type="submit" color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    ];
  }
}

export default withRouter(withStyles(styles)(EditUser));
